import { hot } from 'react-hot-loader'
import * as React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import Routes from './routes'
import { theme } from './theme'

const App = () => (
  <ThemeProvider theme={theme}>
    <Routes />
  </ThemeProvider>
)

export { App }
export default hot(module)(App)
