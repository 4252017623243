import * as React from 'react'

import { About, InVision, ReadingList } from '../data'
import Home from './Home'
import NotFound from './NotFound'
import Router, { IRouteProps } from './Router'

const staticRoutes: IRouteProps[] = [
  {
    component: About,
    exact: true,
    markdown: true,
    path: '/about'
  },
  {
    component: InVision,
    exact: true,
    markdown: true,
    path: '/invision'
  },
  {
    component: ReadingList,
    exact: true,
    markdown: true,
    path: '/reading-list',
  },
  {
    component: Home,
    exact: true,
    path: '/',
  },
  {
    component: NotFound
  }
]

export default () => Router({ routes: staticRoutes })