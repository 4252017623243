import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import Navigation from './Navigation'

interface IHeaderProps {
  title?: string
}

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
`

const Title = styled.h1`
  font-family: ${(props) => props.theme.font.headers};
  font-size: 1rem;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.color.links}
  }
`

function Header({ title = 'Gabe Weaver' }: IHeaderProps) {
  return (
    <StyledHeader>
      <Title>
        <Link to='/'>{title}</Link>
      </Title>
      <Navigation />
    </StyledHeader>
  )
}

export default Header