import * as React from "react"
import { render } from "react-dom"
import { Normalize } from 'styled-normalize'

import App from "./App"

const MOUNT_NODE = document.getElementById("root")

function Root() {
  return (
    <React.Fragment>
      <Normalize />
      <App />
    </React.Fragment>
  )
}
render(<Root />, MOUNT_NODE)
