import * as React from 'react'
import styled from 'styled-components'

import Header from './Header'

interface ILayoutProps {
  children: React.ReactChild
}

const LayoutWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`
function Layout({ children }: ILayoutProps) {
  return (
    <LayoutWrapper>
      <Header />
      {children}
    </LayoutWrapper>
  )
}

export default Layout