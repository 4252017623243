import * as React from 'react'
import { BrowserRouter, Route, RouteComponentProps, RouteProps, Switch } from 'react-router-dom'

import { Layout, MarkdownViewer } from '../components'

interface IRouteProps extends RouteProps {
  id?: number | string
  markdown?: boolean
}
function SelectRenderer({ component: Component, markdown, ...props }: Partial<IRouteProps> & RouteComponentProps) {

  if (markdown) {
    return <MarkdownViewer html={Component} />
  }

  return <Component {...props} />
}

function RouteController({ id, component, ...route }: IRouteProps) {
  return (
    <Route key={id} {...route} render={props => (
      <SelectRenderer component={component} {...route} {...props} />
    )} />
  )
}

function renderRoutes({ routes }: { routes: RouteProps[]}) {
  return routes.map((route, index) => RouteController({ id: index, ...route }))
}

function Router({ routes }: { routes: RouteProps[]}) {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {renderRoutes({ routes })}
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export { IRouteProps }
export default Router