import * as React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const navLinks = [
  {
    label: 'About',
    slug: '/about'
  },
  {
    label: 'Reading List',
    slug: '/reading-list'
  }
]

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.color.links};
  font-family: ${(props) => props.theme.font.body};
  padding: 0 0 0 1rem;
`
function renderLinks() {
  return navLinks.map(({ label, slug }, index) => <StyledNavLink key={index} to={slug}>{label}</StyledNavLink>)
}
function Navigation() {
  return (
    <nav>
      {renderLinks()}
    </nav>
  )
}

export default Navigation