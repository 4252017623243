import { DefaultTheme } from "styled-components"

const theme: DefaultTheme = {
  color: {
    links: '#6092EA',
    textDark: '#000',
    textLight: '#FFF'
  },
  font: {
    headers: '"Slabo 27px", serif',
    body: '"Lato", sans-serif'
  }
}

export { theme }