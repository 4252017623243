import * as React from 'react'
import styled from 'styled-components'

import "prismjs/themes/prism-tomorrow.css"

interface IMarkdownViewer {
  className?: string,
  html: any,
  id?: string
}

function MarkdownViewerElement({ className, html, id }: IMarkdownViewer) {
  return (
    <div id={id} className={className} dangerouslySetInnerHTML={{ __html: html }} />
  )
}

const MarkdownViewer = styled(MarkdownViewerElement)`
  font-family: ${(props) => props.theme.font.body};
  line-height: 1.5rem;

  a {
    color: ${(props) => props.theme.color.links};
  };
  h1, h2 {
    font-family: ${(props) => props.theme.font.headers};
  };
  h2 {
    margin-top: 3rem;
  };
`

export default MarkdownViewer